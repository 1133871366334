.lato-thin {
    font-family: 'Lato', sans-serif;
    font-weight: 100;
    font-style: normal;
}

.lato-light {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    font-style: normal;
}

.lato-regular {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-style: normal;
}

.lato-bold {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-style: normal;
}

.lato-black {
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    font-style: normal;
}

.lato-thin-italic {
    font-family: 'Lato', sans-serif;
    font-weight: 100;
    font-style: italic;
}

.lato-light-italic {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    font-style: italic;
}

.lato-regular-italic {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-style: italic;
}

.lato-bold-italic {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-style: italic;
}

.lato-black-italic {
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    font-style: italic;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
    font-family: 'Lato', sans-serif;
}

body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
    display: block;
    max-width: 100%;
}

input,
button,
textarea,
select {
    font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: break-word;
}

:root {
    --primary-color: #5f46e3;
    --sidbar-background-color: #1a1d1f;
    --primary-text-color: #fff;
    --accordian-background-color-custom: hsla(0, 0%, 78%, 0.25);
}

/* ----------------------------------------------------------------------------------------------------------------- */
#root {
    /* isolation: isolate; */
    width: 100vw;
    height: 100vh;
}

.main-contanier {
    margin-left: 250px;
}

@media (max-width: 766px) {
    .main-contanier {
        margin-left: unset;
    }

    .payment-card {
        box-shadow: 1px 2px 7px -2px black;
        border-radius: 10px;
        position: relative;
    }

    .payment-cross {
        position: absolute;
        top: -14px;
        right: -10px;
    }
}

.navbar-nav div a,
li {
    color: var(--primary-text-color);
}

.rs-picker-popup li {
    color: black;
}

.navbar-horizontal {
    width: 100%;
    padding: 20px;
    background: var(--primary-color);
    box-shadow: 2px -1px 20px 0px black;
    color: #fff;
}

.subject-group-creation-btn {
    position: fixed;
    border: 2px solid #5f46e3;
    box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    bottom: 50px;
    right: 50px;
    cursor: pointer;
    transition: all 200ms ease-in-out;
}

.subject-group-creation-btn:hover {
    transform: rotate(90deg);
    scale: 1.03;
}

.subject-group-creation-btn .icon {
    font-size: 32px;
    position: relative;
}

.custom-input {
    font-family: Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.btn-primary {
    background-color: var(--primary-color) !important;
}

form .note-editor.note-frame .note-editing-area .node-editable {
    color: black !important;
}

fieldset {
    margin-top: 15px !important;
    position: relative !important;
    border: 1px solid rgb(0, 0, 0, 0.2) !important;
    border-radius: 8px !important;
    padding: 20px !important;

    /* padding-top: 20px !important; */
}

legend {
    position: absolute !important;
    top: -1rem !important;
    left: 10px !important;
    padding: 0px 10px !important;
    font-size: 1.3rem !important;
    width: auto !important;
    background-color: white !important;
}

.node-nav-bar div a li,
#SiderBar a,
#SiderBar li {
    color: black;
}

/* doubuts  */
.ticket-id {
    background-color: rgb(95, 70, 227);
    font-size: 18px;
    font-weight: bold;
    width: 40px;
    height: 40px;
    /* padding: 8px; */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.unread-message {
    border-radius: 20px;
    background-color: #0066ff;
    padding: 3px;
    font-weight: bold;
    font-size: 10px;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 40px;
    width: 20px;
    color: white;
}

div .dropdown-menu.dropdown-menu-white {
    display: none;
}

.rdt_TableRow {
    .dropdown {
        position: static !important;

        button {
            border: none !important;
            outline: none !important;
        }

        button::after {
            content: '';
        }
    }
}

div .dropdown-toggle.btn.btn-success {
    background: white;
    color: black;
    border-color: rgba(0, 0, 0, 0);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropend .dropdown-toggle::after {
    border-bottom: none !important;
    border-left: none !important;
}

.custom-input {
    padding: 10px 15px;
    border: 2px solid #ccc;
    /* Default border color */
    border-radius: 5px;
    /* Rounded corners */
    transition: border-color 0.3s ease;
    /* Smooth transition */
    font-size: 16px;
    /* Font size */
}

.custom-input:focus {
    border-color: var(--primary-color);
    /* Change border color on focus */
    outline: none;
    /* Remove outline */
    box-shadow: 0 0 5px var(--primary-color);
    /* Subtle shadow */
}

.custom-input:hover {
    border-color: var(--primary-color);
    /* Darker border on hover */
}

#levels .form-check-input[type='checkbox'] {
    box-shadow: 0px 0px 2px black;
}

.nav-bar-span {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.nav-bar-span:hover {
    color: var(--primary-color);
}

.nav-bar-vertical-toggle {
    box-shadow: 0px 0px black;
}

.navbar-vertical {
    /* position: absolute; */
    top: 0;
    left: 0;
    height: 100vh;
    /* width: 250px; Adjust width based on your layout */
    background-color: #333;
    transition: transform 0.3s ease;
    /* Transition for smooth sliding */
}

.navbar-vertical.closed {
    transform: translateX(-100%);
    /* Slide out of view */
    position: absolute;
    z-index: 10000000;
}

.navbar-vertical.open {
    transform: translateX(0);
    /* Fully visible */
}

.nav-bar-vertical-toggle {
    cursor: pointer;
}

.nav-bar-vertical-toggle.rotate-left {
    transform: rotate(0deg);
    /* Normal direction for left arrow */
}

.nav-bar-vertical-toggle.rotate-right {
    transform: rotate(180deg);
    /* Rotate to make it right arrow */
}

.toggle-button {
    /* Optional: Add any specific styling you want for the button's container */
}

.nav-bar-li {
    color: white;
    padding: 5px 0px;
}

.custome-accordian-background-color {
    background: var(--accordian-background-color-custom);
    padding: 12px;
    border-radius: 8px;
    cursor: pointer;
}

.custome-accordian-background-color:hover {
    box-shadow: 0 1px 8px -5px #000;
}

.active-link {
    /* padding: 5px 0px; */
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.pending-link {
    color: red;
}

.confirmation-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.confirmation-buttons {
    display: flex;
    gap: 0.5rem;
}

.confirmation-yes {
    padding: 0.5rem 1rem;
    background-color: #d9534f;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.confirmation-no {
    padding: 0.5rem 1rem;
    background-color: #5bc0de;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.nav-link:focus,
.nav-link:hover {
    color: var(--primary-color) !important;
}

.active-content {
    background: #5f46e3fc;
    color: white;
    border-radius: 7px;
}

/* .payment-card {
  box-shadow: 0px 0px 5px black;
  border-radius: 10px;
} */

.espanel {
    border: solid 1px #ccc;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
}

.react-select-container {
    z-index: 10000;
}

.text-break {
    overflow: hidden;
    white-space: wrap;
    text-overflow: ellipses;
}

.modal-90 {
    width: 90vw !important;
}

/* @media (min-width: 576px) {
  .modal-dialog {
    max-width: 90vw !important;
  }
} */

.content-schdule-cell {
    /* max-width: 500px; */
    display: flex;
    align-items: center;
}

.table-span {
    display: block;
}

@media (max-width: 1200px) {
    .content-schdule-cell {
        /* max-width: 90vw !important; */
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .table-span {
        display: none;
    }
}

.custom-alert-bg {
    display: none;
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: #00000060;
    z-index: 1;
    align-items: flex-start;
    justify-content: center;
}

.custom-alert {
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    border: 1px solid;
    padding: 15px;
    box-shadow: 0px 0px 4px 1px;
    margin-top: 20px;
}

.custom-alert .title {
    font-size: 20px;
    margin-bottom: 5px;
}

.custom-alert .message {
    font-size: 16px;
    margin-block: 10px;
}

.rs-picker-popup.rs-picker-popup-daterange {
    z-index: 10000000000000000;
}

/* body::-webkit-scrollbar {
  display: none;
} */

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    top: -222px;
    left: 477px;
}

/* Track */
::-webkit-scrollbar-track {
    /*box-shadow: inset 0 0 5px grey; */
    border-radius: 10px;
    background-color: var(--Primary-Background-Color);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #e6e7e9;
    border-radius: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #c7cbd1;
}

/* Hide arrows in Chrome, Safari, Edge, and Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-floating>label {
    z-index: 0 !important;
}


.text-breaker {
    overflow: hidden;
    white-space: wrap;
    text-overflow: ellipsis;
    cursor: auto;
}
.tile {
    width: 12rem;
    height: 10rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;
    border-radius: 1.5rem;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.tile:hover {
    transform: scale(1.05);
}
