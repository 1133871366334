#singleDoubt {

  .chattingpage {
    background-color: white;
    width: 70%;
    box-shadow: 0 2px 4px rgb(15 34 58 / 12%);
    height: 100vh;
  }

  @media screen and (max-width: 800px) {
    .chattingpage {
      width: 100%;
    }
  }

  .home-cont {
    display: flex;
    justify-content: space-around;
  }

  .top-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid #f0eff5;
  }

  .user-header {
    display: flex;
    align-items: center;
  }


  .user-fet {
    color: #6f7178;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-right: 20px;
  }

  .live-chat {
    padding: 20px;
    max-height: 90%;
    height: 90%;
    box-sizing: border-box;
    overflow-y: scroll;
  }


  .live-chat::-webkit-scrollbar {
    width: 10px;
  }

  .live-chat::-webkit-scrollbar-thumb {
    background: #c4ccdbb2;
    border-radius: 10px;
  }

  .leftuser-chat {
    display: flex;
    flex-direction: row-reverse;
  }

  .rihgtuser-chat {
    display: flex;
  }

  .right-avt {
    /* border: 2px solid red; */
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: right;
    max-width: 80%;
    color: #fff;
  }

  .left-avt {
    /* border: 2px solid black; */
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 10px;
    max-width: 80%;
  }

  .left-msg {
    background-color: #7269ef;
    padding: 8px;
    border-radius: 10px;
    margin: 3px;

  }

  .right-msg {
    background-color: #000;
    padding: 8px;
    border-radius: 10px;
    margin: 3px;
  }

  .left-msg p {
    margin: 0px;
    color: white;
  }

  .right-msg p {
    margin: 0px;
  }

  .time {
    font-size: 12px;
  }

  .sender-cont {
    display: flex;
    justify-content: space-around;
    padding: 15px;
  }


}

.chat-header {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff ;
  color: #ffffff;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.back-button button {
  background: none;
  border: none;
  color: #000E08;
  font-size: 1.5rem;
  margin-right: 15px;
  cursor: pointer;
}

.user-info {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.user-avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 15px;
  border: 1px solid #c3c3c3;
}

.user-details {
  display: flex;
  flex-direction: column;
}

.user-name {
  font-size: 1.2rem;
  font-weight: bold;
  color: #000E08;
}

.doubt-status {
  font-size: 0.9rem;
  color: #000E08;
}

.timestamp {
  font-size: 0.8rem;
  color: #000E08;
}

.doubt-status {
  border: none;
  background-color: transparent;
  color: #000E08; 
  font-size: 1rem; 
  cursor: pointer;
  outline: none;
}

.doubt-status option {
  color:#000E08
}

