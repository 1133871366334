.chatbox-message {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 10px 0;
    max-width: 70%;
  }
  
  .chatbox-message.self {
    align-items: flex-end;
  }
  
  .chatbox-message.other {
    align-items: flex-start;
  }
  
  .chatbox-content {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .user-icon {
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #5F46E3 ;
    overflow: hidden;
  }
  
  .user-icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .message-content {
    background-color: #e2ffc7;
    padding: 10px 15px;
    border-radius: 15px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .chatbox-message.self .message-content {
    background-color: #20A090 ;
    color:white;
    border-radius: 15px 15px 0 15px;
  }
  
  .chatbox-message.other .message-content {
    background-color: #F2F7FB;
    border-radius: 0px 15px 15px 15px;
  }
  
  .text-message {
    margin: 0;
    font-size: 1rem;
    color: #333;
  }
  .chatbox-message.self .message-content .text-message {
    color:#FFFFFF
  }


  .media-content {
    max-width: 250px;
    border-radius: 10px;
    margin: 5px 0;
  }
  
  .message-date {
    font-size: 0.75rem;
    color: #888;
    text-align: right;
    margin-top: 5px;
  }
  
  .chatbox-message.self  .message-date {
    color:#adadad;
    margin-right: 10px;
  }

  .chatbox-message.other  .message-date {
    text-align: start;
    margin-left: 50px;
  }
  
  


  /* CHAT FOOTER DESIGN */
  .chatbox-footer {
    display: flex;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #e0e0e0;
    background-color: #f5f5f5;
  }
  
  .icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border: none;
    background-color: transparent;
    color: #888;
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  .message-input {
    flex-grow: 1;
    padding: 8px 10px;
    margin: 0 10px;
    border: none;
    border-radius: 20px;
    background-color: #fff;
    font-size: 1rem;
  }
  
  .message-input:focus {
    outline: none;
  }
  
  .send-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border: none;
    background-color: #34b7f1;
    color: #fff;
    font-size: 1.2rem;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .send-button:hover {
    background-color: #128c7e;
  }
  

  .image-preview {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
}

.image-preview img {
    max-width: 100px;
    max-height: 100px;
    border-radius: 8px;
    object-fit: cover;
}

.cancel-preview {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(0, 0, 0, 0.6);
    border: none;
    color: white;
    border-radius: 50%;
    cursor: pointer;
    padding: 4px;
}









