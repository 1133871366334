.collection-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    max-width: 600px;
    border-radius: 8px;
}

.content {
    height: 400px;
    margin: 10px 20px;
}


.list {
    height: 440px;
    overflow-y: scroll;
    border:1px solid #e0e0e0;
    background-color: white;
    padding: 0.5rem 1rem;
    margin:0.5rem 1rem;
    width: 45rem !important;
    border-radius: 0.4rem;
    position:relative
}

.list .btn-holder{
    position:sticky;
    top:0px;
    background-color: white;
    margin-top: none !important;

}