:root {
  --quiz-question-label: #6E7191;
}


/*------------------------------------------------------------------------------Quiz Classes----------------------------------------------------------------------------------------------------------------------------------------------- */
#Quiz button.btn:first-child:active,
#Quiz :not(.btn-check)+.btn:active,
#Quiz button.btn:hover {
  color: var(--second-primary-color) !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}


.app {
  display: flex;
  flex-direction: column;
  height: 100vh;

}

.header {
  flex-shrink: 0;
  padding-bottom: 0px;
  z-index: 9;

}


.horizontal-scroll {
  display: flex;
  overflow-x: scroll;
  padding-bottom: 10px;
  scroll-behavior: smooth;
}

.horizontal-scroll button {
  margin-right: 10px;
  border-radius: 20px;
}

.content {
  flex-grow: 1;
  overflow-y: scroll;
  padding: 10px;
  padding-bottom: 70px;
}

.vertical-scroll {
  height: 1400px;
}

.footer {
  flex-shrink: 0;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #FFF;
  box-shadow: 0px 0px 5px 0px;
  z-index: 9;
}

img {
  max-width: -webkit-fill-available;
}

.input {
  padding: 6px;
  width: -webkit-fill-available;
  border-color: #6E7191;
  border-radius: 8px;
  font-size: large;
  outline: none;
}

.opt {
  display: flex;
  padding: 15px 0 0 15px;
  align-items: flex-start;
  border: 2px solid #FFF;
  border-radius: 15px;
}

.opt-selected {
  border: 2px solid #5F46E3;

}

.opt-correct {
  border: 2px solid #008444;
}

.opt-incorrect {
  border: 2px solid #CC2C2C;
}

.index-btn {
  display: flex;
  padding: 20px;
  margin: 10px;
  border-radius: 50%;
  place-content: center;
  height: 40px;
  width: 40px;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}

.meta-data {
  color: #6E7191;
  font-weight: bold;
  margin-bottom: 15px;
}

.new-btn-outline {
  border: 2px solid rgb(95, 70, 227);
  border-radius: 0.3rem;
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  color: rgb(95, 70, 227);
  font-weight: bold;
}

.new-btn-outline[disabled] {
  opacity: 0.5;
  border: 2px solid rgb(51, 58, 71);
  color: rgb(51, 58, 71)
}


.solve-cast img {
  filter: invert(100%);
}


.insights .accordion {
  background-color: rgb(200 200 200 / 11%);
  /* padding: 10px; */
  border-radius: 8px;
}



#quiz-wrong-message {
  color: rgb(95, 70, 227) !important;
  text-decoration: underline !important;
  cursor: pointer;
}


.offcanvas-bottom {
  height: auto !important;
}


.currentAccordian:hover {
  cursor: pointer;
  box-shadow: 0px 1px 8px -5px black !important;
}

.vertical-scroll {
  height: 1400px;
}



.draw-tool-container {
  position: absolute;
  text-align: -webkit-center;
  width: 100%;
}


.quiz-question table,
.quiz-question th,
.quiz-question td {
  border: 1px solid !important;
  border-collapse: collapse !important;
}


.solve-cast .quiz-question,
.solve-cast .quiz-question .katex {
  font-size: .8rem;
}

.solve-cast .quiz-question .katex-display {
  display: unset;
}


.solve-cast .accent-body {
  left: -0.2722em !important;
  top: -3px !important;
}


.option {
  display: flex;
  flex: 1;
  min-width: fit-content;
  max-width: 100%;
  margin: 5px 5px 0 0;
  align-items: baseline;
  /* 
  margin-top: 5px;
  flex: 1 0 25%;
  /* min-width: max-content; */
}

.option-selected {
  width: 100;
  height: auto;
  border-radius: 10px;
  border: 2px solid white;
  padding: .5rem;
}