.sidebar {
    /* position: fixed; */
    position: relative;
    top: 0;
    right: 0;
    width: 250px;
    height: 100vh;
    background: #f8f9fa;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
    z-index: 1;
}

.sidebar.active {
    transform: translateX(0);
}
.content {
    transition: all 0.4s ease-in-out;
    height: 100% !important;
}

.content.active {
    margin-right: 70px !important;
}
