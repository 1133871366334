.collection-page {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.edit-btn {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.edit-btn:hover {
    background-color: #0056b3;
}

h1 {
    font-size: 24px;
    margin-bottom: 20px;
}
