.fixed-table {
    max-height: 30rem;
    overflow-y: scroll;

}
.fixed-column {
    height: 30rem;
}
.fixed-input {
    position:sticky; 
    top:0px;
    padding:5px;
    width: 100%;
    background-color: white;
}

@media (max-width:768px){
    .fixed-column {
        transform: rotate(90deg);
        height: 5rem;
    }
}