.testimonial-form-container {
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .testimonial-form-container h3 {
    font-weight: 600;
  }
  
  .testimonial-form-container .form-control {
    border-radius: 4px;
  }
  
  .testimonial-form-container button[type="submit"] {
    transition: background-color 0.3s ease;
  }
  
  .testimonial-form-container button[type="submit"]:hover {
    background-color: #007aff;
    border-color: #007aff;
  }
  