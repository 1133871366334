.login-body {
	background: linear-gradient(-45deg, #ee7752, #5F46E3, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	height: 100vh;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


@keyframes rotateBackground {
  0% {
    background: radial-gradient(var(--primary-color), #000);;
  }
  100% {
    background: radial-gradient(#000, var(--primary-color));;
  }
}


.login-card {
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.btn-gradient,
.btn-gradient:hover {
  background: linear-gradient(to right, var(--primary-color), var(--primary-color));
  border: none;
  transition: background 0.3s ease-in-out;
}


.alert-danger {
  background-color: #f8d7da;
  color: #721c24;
  border-color: #f5c6cb;
}



.otp-verifie .form {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  width: 300px;
  background-color: white;
  border-radius: 12px;
  padding: 20px;
}

.otp-verifie .title {
  font-size: 20px;
  font-weight: bold;
  color: black
}

.otp-verifie .message {
  color: #a3a3a3;
  font-size: 14px;
  margin-top: 4px;
  text-align: center
}

.otp-verifie .inputs {
  margin-top: 10px
}

.otp-verifie .inputs input {
  width: 32px;
  height: 32px;
  text-align: center;
  border: none;
  border-bottom: 1.5px solid #d2d2d2;
  margin: 0 10px;
}

.otp-verifie .inputs input:focus {
  border-bottom: 1.5px solid royalblue;
  outline: none;
}

.otp-verifie .action {
  margin-top: 24px;
  padding: 12px 16px;
  border-radius: 8px;
  border: none;
  background-color: royalblue;
  color: white;
  cursor: pointer;
  align-self: end;
}