.content-card {
    width: 14rem;
    margin: 0.1rem;
    border-radius: 8px;
    height: 100%;
    cursor: pointer;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
}
.content-card:hover {
    transform: translateY(-5px);
    box-shadow: rgba(95, 70, 227, 0.3) 0px 19px 38px, rgba(95, 70, 227,0.22) 0px 15px 12px !important;
}

.content-card-img {
    width: 100%;
    aspect-ratio: 3/2;
    object-fit: cover;
}

.card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* flex-grow: 1; */
}

.card-title {
    font-size: 15px;
    text-align: start;
    margin-bottom: 0.5rem;
    font-weight: 600;
}

.description {
    margin:0px;
    color: #5F46E3;
    font-weight: 700;
    padding: 3px;
    border-radius: 3px;
    font-size: 12px;
}