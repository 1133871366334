.heading {
    font-size: 2rem;
    text-align: center;
    display: block;
}
fieldset{
    height: 100%;
}
.custom-check-1,
.custom-check-2 {
    justify-content: flex-end;
    flex-direction: row-reverse;
}

.custom-check-1 {
    gap: 8rem;
}

.custom-check-2 {
    gap: 10rem;
}

/* Mobile Screen: Below 600px */
@media only screen and (max-width: 600px) {
    .heading {
        margin-top: 15px;
        font-size: 1.8rem;
    }

    fieldset {
        margin-top: 25px !important;
    }

    .sets {
        flex-direction: column-reverse !important;
    }
}

/* Small to Medium Screens: Below 1000px */
@media only screen and (max-width: 1000px) {
    .custom-check-1 {
        gap: 3rem !important;
    }

    .custom-check-2 {
        gap: 5.2rem !important;
    }
}

/* Large Screens: 1000px - 1200px */
@media only screen and (max-width: 1200px) {
    .custom-check-1 {
        gap: 3rem !important;
    }

    .custom-check-2 {
        gap: 3.2rem !important;
    }
}

/* Larger Screens: 1200px - 1400px */
@media only screen and (max-width: 1400px) {
    .custom-check-1 {
        gap: 3rem !important;
    }

    .custom-check-2 {
        gap: 5rem !important;
    }
}

/* Extra Large Screens: 1400px - 1600px */
@media only screen and (max-width: 1600px) {
    .custom-check-1 {
        gap: 5rem;
    }

    .custom-check-2 {
        gap: 7rem;
    }
}
.btn:hover {
    filter: contrast(0.7) !important;
}