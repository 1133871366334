.customFileUpload {
    height: 200px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    gap: 20px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border: 2px dashed #cacaca;
    background-color: rgba(255, 255, 255, 1);
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 0px 48px 35px -48px rgba(0,0,0,0.1);
   
  }
  .customFileUpload  input {
    display: none;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon  svg {
        height: 80px;
        fill: rgba(75, 85, 99, 1);
  }
  
  
  .text {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text  span {
        font-weight: 400;
        color: rgba(75, 85, 99, 1);
      }
  
 